import React from 'react';
import Layout from '../components/Layout/index';
import ServicePage from '../components/pages/services/index';

export default () => (
  <Layout>
    <ServicePage selectedTab="apps">
      <p>
        We are a full service tech firm, specializing in both web and mobile applications. Whether you are looking to
        implement a CMS, eCommerce platform, or tailored application, we are here to help.
      </p>
      <p>
        As much as brand identity needs to be unique, neither should apps be created from an existing model. We work
        closely with you to understand your requirements to deliver a product that aligns with your vision. From initial
        conception to deployment, we offer world-class app development with modern UI/UX design.
      </p>
    </ServicePage>
  </Layout>
);
