import React from 'react';
import Contact from '../../Contact';
import Container from '../../Container';
import Header from '../../Header';
import Link from '../../Link';

import skyline from '../../../../static/skyline.svg';

import styles from './index.module.scss';

const links = [
  { key: 'apps', text: 'Apps', url: '/apps' },
  { key: 'websites', text: 'Websites', url: '/websites' },
  { key: 'branding', text: 'Branding', url: '/branding' }
];

export default ({ children, className, selectedTab = 'apps' }) => (
  <div className={`${styles.root} ${className}`}>
    <Header />
    <div className={styles.text}>
      <Container>
        <div className={styles.links}>
          {[links.find(l => l.key === selectedTab), ...links.filter(l => l.key !== selectedTab)].map(
            ({ key, text, url }) => (
              <Link className={styles.link} key={key} exact to={url}>
                {text}
              </Link>
            )
          )}
        </div>
        {children}

        <Contact showGetInTouch className={styles.contact} />
      </Container>
    </div>
    <img className={styles.skyline} src={skyline} alt="skyline" />
  </div>
);
